import { createAsync, type RouteSectionProps } from "@solidjs/router";
import { children, createEffect } from "solid-js";
import { setState } from "~/data/store";
import { fetchVitals } from "~/utils/helpers";
export const route = {
  load: () => fetchVitals(),
};
const Index = (props: RouteSectionProps) => {
  const data = createAsync(() => fetchVitals());
  createEffect(() => {
    setState({ ...data() });
  });

  const resolved = children(() => props.children);

  return (
    <div class="relative rounded-full px-1 py-1 text-sm leading-6 surface1 ff-sans-serif">
      {resolved()}
    </div>
  );
};

export default Index;
